import {
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { ImPhone } from "react-icons/im";
import { Link } from "react-scroll";
import backMariaCel from "../img/backMariaCel.png";
import mariaback from "../img/mariaback.png";

export default function WithBackgroundImage() {
  const [largura, setLargura] = React.useState(window?.innerWidth);
  var windowNav = window?.innerWidth;
  React.useEffect(() => {
    setLargura(windowNav);
  }, [windowNav]);

  return (
    <Flex
      w={"full"}
      h={"100vh"}
      backgroundImage={
        largura > 820 ? `url(${mariaback})` : `url(${backMariaCel})`
      }
      backgroundSize={"cover"}
      // backgroundPosition={"center center"}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
      >
        <Stack maxW={"2xl"} align={"flex-start"} spacing={6}>
          <Text
            color={"white"}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: "3xl", md: "4xl" })}
          >
            MS Estética e Beleza
          </Text>
          <Text
            color={"gray.100"}
            fontWeight={500}
            lineHeight={1.3}
            fontSize={useBreakpointValue({ base: "1xl" })}
          >
            {" "}
            Agende já o seu horário na MS Estética e Beleza, estúdio localizado
            no coração de Brasília e com serviços de excelência!
          </Text>

          <Stack direction={"row"}>
            <a href={"/curso"}>
              <Button
                bg={"primary.900"}
                rounded={"full"}
                color={"white"}
                _hover={{ bg: "primary.1100" }}
               >
                Sobre o curso
              </Button>
            </a>

            <a
              target={"_blank"}
              href={
                "https://api.whatsapp.com/send?phone=5561995591218&text=Oie+Ma%21%21%F0%9F%92%95%F0%9F%8C%B7+gostaria+de+agendar+um+hor%C3%A1rio+com+voc%C3%AA%21%21%21%E2%98%BA%EF%B8%8F"
              }
            >
              <Button
                leftIcon={<ImPhone />}
                bg={"#E5C0AC"}
                rounded={"full"}
                target={"_blank"}
                color={"black"}
                _hover={{ bg: "primary.600" }}
              >
                Agende um horário
              </Button>
            </a>
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
}
