import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Cursos from "./pages/Cursos";
import Landing from "./pages/Landing";
import Links from "./pages/Links";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/links" component={Links} />
        <Route exact path="/curso" component={Cursos} />
      </Switch>
    </Router>
  );
};

export default Routes;
