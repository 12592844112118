import {
  Box,
  Button,
  Center,
  chakra,
  Container,
  Flex,
  Grid,
  GridItem,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  FcAddressBook,
  FcAlarmClock,
  FcApproval,
  FcGraduationCap,
} from "react-icons/fc";

/* interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
} */

const Feature = ({ title, text, icon }) => {
  return (
    <Stack align={"center"} justify={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
      >
        {icon}
      </Flex>
      <Text align={"center"} justify={"center"} fontWeight={600}>
        {title}
      </Text>
      <Text align={"center"} justify={"center"} color={"gray.300"}>
        {text}
      </Text>
    </Stack>
  );
};

export default function colunaSimples3() {
  return (
    <Box as={Container} maxW="6xl" mt={8} p={8}>
      <Grid gap={4} id="curso">
        <GridItem colSpan={1}>
          <VStack alignItems="center" spacing="20px">
            <chakra.h2 fontSize="3xl" fontWeight="700">
              Curso De Design de Sobrancelhas
            </chakra.h2>
          </VStack>
          <VStack alignItems="center" spacing="20px" mt={5}>
            <chakra.h2 fontSize="1xl" fontWeight="700">
              Vire uma Designer de referência
            </chakra.h2>
          </VStack>
          <Flex mt={4} maxW="5xl">
            <Text align={"center"}>
              O curso de design de sobrancelhas é uma ótima oportunidade para
              aqueles que desejam aprimorar suas habilidades criativas e entrar
              no mundo da beleza e estética facial. Durante o curso, os nosso
              alunos têm a chance de aprender técnicas especializadas para
              moldar e embelezar as sobrancelhas, realçando a expressão facial
              de seus clientes.
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      <Grid mt={12}>
        <VStack alignItems="center" spacing="20px">
          <chakra.h2 fontSize="2xl" fontWeight="700">
            O Que Oferecemos:
          </chakra.h2>
        </VStack>
      </Grid>
      <SimpleGrid
        style={{ marginTop: "30px" }}
        columns={{ base: 1, md: 4 }}
        spacing={4}
      >
        <Feature
          icon={<Icon as={FcApproval} w={10} h={10} />}
          title={"Incluso no Curso"}
          text={[
            "- Aulas teóricas (individuais)",
            <br />,
            " - Aula prática (individual)",
            <br />,
            " - Suporte pós curso sempre que precisar",
            <br />,
            " - Materiais (pinça, escovinhas, linha, tesoura, palitinhos, lápis dermatográfico, dappen e paquímetro)",
            <br />,
            "- Apostila digital/impressa",
            <br />,
            "- Certificado digital/impresso.",
            <br />,
            <br />,
            <br />,
          ]}
        />
        <Feature
          icon={<Icon as={FcAddressBook} w={10} h={10} />}
          title={"Conteúdos Ministrados"}
          text={[
            "- Mapeamento facial ",
            <br />,
            "- Design profissional de sobrancelhas ",
            <br />,
            "- Design profissional com aplicação de henna ",
            <br />,
            "- Biossegurança ",
            <br />,
            "- Redes sociais e divulgação ",
            <br />,
            "- Aprenda a tirar fotos de resultados ",
            <br />,
            "- Fidelização de clientes ",
            <br />,
            "- Uso de paquímetro ",
            <br />,
            " - Técnicas de pinçamento.",
          ]}
        />
        <Feature
          icon={<Icon as={FcAlarmClock} w={10} h={10} />}
          title={"Carga Horária e valores"}
          text={[
            "- Duração de 18h ",
            <br />,
            "- 3 dias de curso ",
            <br />,
            "- Todo suporte necessário após a conclusão do curso",
            <br />,
            "- R$ 750,00 à vista ou 850,00 parcelado no cartão de crédito.",
            <br />,
            <br />,
            <br />,
            <br />,
          ]}
        />
        <Feature
          icon={<Icon as={FcGraduationCap} w={10} h={10} />}
          title={"Certificado"}
          text={[
            "Após a conclusão do curso, você receberá um certificado digital e impresso.",
            <br />,
            <br />,
            <br />,
            <br />,
            <br />,
            <br />,
            <br />,
            <br />,
          ]}
        />
      </SimpleGrid>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        gap={4}
        mt={12}
      >
        <GridItem colSpan={1}>
          <VStack alignItems="flex-start" spacing="20px">
            <chakra.h2 fontSize="3xl" fontWeight="700">
              Próximos Passos
            </chakra.h2>
            <a
              target={"_blank"}
              href={
                "https://api.whatsapp.com/send/?phone=5561995591218&text=Ol%C3%A1%2C+Eu+gostaria+de+investir+na+minha+carreira+e+saber+mais+sobre+o+curso+de+designer.&type=phone_number&app_absent=0"
              }
            >
              <Button colorScheme="pink" size="md" bg={"#E5C0AC"} m={2}>
                Quero Investir
              </Button>
            </a>
          </VStack>
        </GridItem>
        <GridItem>
          <chakra.h2 fontSize="2xl" fontWeight="700">
            Invista em você!
          </chakra.h2>
          <chakra.p>
            Deseja possuir habilidades como designer profissional de
            sobrancelhas e trilhar um caminho de sucesso na área da beleza?
            Nosso Curso de Design de Sobrancelhas é o passo certo para você. Com
            um currículo abrangente e orientado para resultados, estamos aqui
            para ajudá-lo a alcançar seus objetivos e construir uma carreira de
            destaque no mundo do design de sobrancelhas.
          </chakra.p>
        </GridItem>
      </Grid>
    </Box>
  );
}
