import React from "react";

import Routes from "./Routes";

export default function App() {
  return (
    <>
  <Routes/>
  </>
  );
}
