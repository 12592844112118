import { Flex, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function LandingLayout(props) {

  return (
    <Flex
      direction="column"
      minH="100%"
      minW='100%'
      bg={useColorModeValue("gray.100", "gray.900")}
    
      {...props}
    >
      {/* <Header /> */}
      {props.children}
    </Flex>
  );
}
