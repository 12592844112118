import { Avatar, Box, Link, Text } from "@chakra-ui/react";
import React from "react";
import MSLogo from "../img/MSLogo.jpg";

export default function Logo({ colorMode }) {
  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold">
        <Link target={'_blank'} href="https://www.mariasalesdesigner.com/">
          <Avatar
            name="Maria Sales Design"
            width="10"
            height="10"
            src={`${MSLogo}`}
          />
        </Link>
      </Text>
    </Box>
  );
}
