import {
  Box,
  Button,
  Center,
  chakra,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import imagemsombra2 from "../img/imagemsombra2.jpeg";
import imagemsombra4 from "../img/imagemsombra4.jpeg";
import sombra1 from "../img/sombra1.jpeg";
import sombra5 from "../img/sombra5.jpeg";
import ProductCard from "./ProductCard";

export default function gridListWithCTA() {
  let texto1 = "Toda mulher precisa da paz que uma sobrancelha feita traz.";
  let texto2 =
    "Trilhe o caminho do sucesso. Só não se esqueça de ir com as sobrancelhas perfeitas.";
  let texto3 =
    "As sobrancelhas são a única coisa que você pode deixar em forma sem precisar fazer exercícios.";
  let texto4 = "Sobrancelhas perfeitas abrem caminhos.";
  return (
    <Box as={Container} maxW="7xl" mt={14} p={4}>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        gap={4}
      >
        <GridItem colSpan={1}>
          <VStack alignItems="flex-start" spacing="20px">
            <chakra.h2 fontSize="3xl" fontWeight="700">
              Designer de sobrancelhas e professora, atuando em Brasília.
            </chakra.h2>
            <Link
              target={"_blank"}
              href={
                "https://api.whatsapp.com/send?phone=5561995591218&text=Oie+Ma%21%21%F0%9F%92%95%F0%9F%8C%B7+gostaria+de+agendar+um+hor%C3%A1rio+com+voc%C3%AA%21%21%21%E2%98%BA%EF%B8%8F"
              }
            >
              <Button
                 bg={"#E5C0AC"}
                  target={"_blank"}
                 color={"black"}
                 _hover={{ bg: "primary.600" }}
                size="md"
              >
                Entrar em contato
              </Button>
            </Link>
          </VStack>
        </GridItem>
        <GridItem>
          <Flex>
            <chakra.p>
              Maria Paula Sales é uma designer de sobrancelhas, professora e
              mentora na área. Atua em Brasília, com muita dedicação e
              experiência. Seu trabalho é reconhecido por sua qualidade e
              excelência, e ela é referência na área.
              <br />
              <br />O design é personalizado e feito respeitando o formato
              original das sobrancelhas das clientes. Além disso, é utilizado
              mapeamento facial para definir as métricas e proporções mais
              adequadas a cada par de sobrancelhas.
            </chakra.p>
          </Flex>
        </GridItem>
      </Grid>

      <Divider mt={12} mb={12} />

      <Center height="80px">
        <chakra.h2 fontSize="2xl" fontWeight="700">
          Alguns de meus trabalhos
        </chakra.h2>
      </Center>
      <SimpleGrid
        style={{ marginTop: "10px" }}
        columns={[1, null, 4]}
        spacing={5}
      >
        <Box height="100%">
          <ProductCard imagem={imagemsombra2} textoImage={texto1} />
        </Box>
        <Box height="100%">
          <ProductCard imagem={sombra1} textoImage={texto2} />
        </Box>
        <Box height="100%">
          <ProductCard imagem={sombra5} textoImage={texto3} />
        </Box>
        <Box height="100%">
          <ProductCard imagem={imagemsombra4} textoImage={texto4} />
        </Box>
      </SimpleGrid>
    </Box>
  );
}
