import {
  Avatar,
  Button,
  Center,
  Flex,
  Link,
  Stack,
  Text,
  useColorMode, Wrap,
  WrapItem
} from "@chakra-ui/react";
import React from "react";
import { BsBook } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { FiArrowUpLeft } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import caraMaria from "../components/img/caraMaria.jpeg";

export default function Links() {
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();

  React.useEffect(() => {
    if (colorMode === "light") {
      toggleColorMode();
    }
  });

  return (
    <Flex
    direction="column"
    minH="100%"
    minW='100%'
    // bg={useColorModeValue("gray")}
  
     
  >
      <Center p={8}>
        <Stack spacing={2} align={"center"} maxW={"md"} w={"full"}>
          <Wrap style={{marginTop:"10%"}}>
            <WrapItem>
              <Avatar size="xl" name="Maria Sales Designer" src={`${caraMaria}`} />{" "}
            </WrapItem>
          </Wrap>
          <Text as="b" fontSize="2xl">
            Maria Sales Designer
          </Text>
          <Text align="center"fontSize="md">
          "Trilhe o caminho do sucesso, só não se esqueça de ir com as sobrancelhas perfeitas."
          </Text>

          <Link
            style={{ marginTop: "6%" }}
            w={"full"}
            target={"_blank"}
            href={
              "https://api.whatsapp.com/send?phone=5561995591218&text=Oie+Ma%21%21%F0%9F%92%95%F0%9F%8C%B7+gostaria+de+agendar+um+hor%C3%A1rio+com+voc%C3%AA%21%21%21%E2%98%BA%EF%B8%8F"
            }
          >
            <Button
              w={"full"}
              bg={"#E5C0AC"}
              color={"black"}
              _hover={{ bg: "primary.600" }}
              leftIcon={<FaWhatsapp />}
            >
              <Center>
                <Text>Agende seu horário</Text>
              </Center>
            </Button>
          </Link>

          {/* informação */}
          <Link w={"full"} href={"/curso"}>
            <Button w={"full"} variant={"outline"} leftIcon={<BsBook />}>
              <Center>
                <Text>Informações sobre o curso</Text>
              </Center>
            </Button>
          </Link>

          {/* my site */}
          <Button
            onClick={() => history.push("/")}
            w={"full"}
            leftIcon={<FiArrowUpLeft />}
          >
            <Center>
              <Text>Visite meu site!</Text>
            </Center>
          </Button>
          <Link w={"full"} href={"/#localizacao"}>
            {/* localização */}
            <Button w={"full"} leftIcon={<IoLocationOutline />}>
              <Center>
                <Text>Localização</Text>
              </Center>
            </Button>
          </Link>
        </Stack>
      </Center>
    </Flex>
  );
}
