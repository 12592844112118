import {
  Button,
  Container,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

export default function sobreLocalizacao() {
  const moment = require("moment");

  // Mapeamento dos nomes dos meses em português
  const mesesEmPortugues = {
    January: "janeiro",
    February: "fevereiro",
    March: "março",
    April: "abril",
    May: "maio",
    June: "junho",
    July: "julho",
    August: "agosto",
    September: "setembro",
    October: "outubro",
    November: "novembro",
    December: "dezembro",
  };

  moment.updateLocale("en", {
    months: Object.values(mesesEmPortugues),
  });

  const mesFormatado = moment().format("MMMM");
  return (
    <Container maxW={"5xl"} style={{ borderRadius: "20px" }} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={6}>
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            p={1}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Promoção!
          </Text>
          <Heading>Desconto exclusivo para o mês de {mesFormatado}</Heading>
          <Text color={"gray.500"} fontSize={"lg"}>
            PROMOÇÃO DE 10% NO DESIGN DE SOBRANCELHAS PARA VISITANTES DO NOSSO
            SITE
          </Text>

          <Link
            target={"_blank"}
            href={
              "https://api.whatsapp.com/send?phone=5561995591218&text=Ol%C3%A1%20Maria%2C%20gostaria%20de%20agendar%20um%20hor%C3%A1rio%20com%20voc%C3%AA!!%F0%9F%98%8A%F0%9F%92%95%20(tenho%20desconto%20de%2010%25%20no%20pix%20porque%20vim%20atrav%C3%A9s%20do%20site)"
            }
          >
            <Button
              bg={"#E5C0AC"}
              target={"_blank"}
              color={"white"}
              _hover={{ bg: "primary.600" }}
            >
              Agendar um Horário com desconto.
            </Button>
          </Link>

          <Stack
            spacing={4}
            divider={<StackDivider borderColor={"gray.100"} />}
          ></Stack>
        </Stack>
        <Flex id="localizacao">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.352336715906!2d-47.8926123850234!3d-15.785365089056912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3af9b893346d%3A0x39f3371acd7e4caa!2sCentro%20Empresarial%20Norte!5e0!3m2!1spt-BR!2sbr!4v1673615638440!5m2!1spt-BR!2sbr"
            width="100%"
            height="100%"
            title="Localização"
            style={{ border: "5px solid #000", borderRadius: "20px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </Flex>
        Asa Norte, Centro empresarial norte, Sala 430 bloco A, Brasília - DF
      </SimpleGrid>
    </Container>
  );
}
