import React from "react";

import LandingLayout from "../components/layouts/LandingLayout";
import ColunaSimples3 from "../components/sections/colunaSimples3";
import Footer from "../components/sections/Footer";
import MaisSobre from "../components/sections/MaisSobre";
import SobreLocalizacao from "../components/sections/SobreLocalizacao";
import WithBackgroundImage from "../components/sections/WithBackgroundImage";

export default function Landing() {

  return (
    <LandingLayout>
      <WithBackgroundImage />
      <MaisSobre />
      {/* <ColunaSimples3 /> */}
      <SobreLocalizacao />
      
      <Footer />
    </LandingLayout>
  );
}
