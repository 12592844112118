import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import ColunaSimples3 from "../components/sections/colunaSimples3";

import React, { useState } from "react";
import {
  FcApproval,
  FcBullish,
  FcManager,
  FcTabletAndroid,
  FcVip,
} from "react-icons/fc";
import sobrancelhacurso from "./../components/img/sobrancelhacurso2.jpg";
import Footer from "./../components/sections/Footer";
import { Link } from "react-scroll";

export default function Cursos() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  React.useEffect(() => {
    if (colorMode === "light") {
      toggleColorMode();
    }
  });

  return (
    <>
      <Container maxW={"7xl"}>
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "4xl", sm: "4xl", lg: "5xl" }}
            >
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "#E5C0AC",
                  zIndex: -1,
                }}
              >
                MS
              </Text>
              <br />
              <Text as={"span"} color={"#E5C0AC"}>
                Cursos e Consultoria
              </Text>
            </Heading>
            <Text color={"gray.100"} fontSize={"lg"}>
              Desenvolva suas habilidades em design de sobrancelhas conosco e
              abra caminho para o sucesso profissional! Oferecemos cursos
              abrangentes e consultorias especializadas para ajudar você a
              aprimorar suas técnicas, conquistar novos clientes e prosperar no
              mundo do design de sobrancelhas.
            </Text>
            <Stack
              spacing={{ base: 4, sm: 4 }}
              direction={{ base: "column", sm: "row" }}
            >
              <Link
                to="section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => {
                  setTabIndex(0);
                }}
              >
                <Button
                  rounded={"full"}
                  size={"md"}
                  fontWeight={"normal"}
                  px={6}
                  colorScheme={"pink"}
                  bg={"#E5C0AC"}
                  _hover={{ bg: "pink.100" }}
                >
                  Consultoria
                </Button>
              </Link>
              <Link
                to="section"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Button
                  rounded={"full"}
                  size={"md"}
                  fontWeight={"normal"}
                  px={6}
                  onClick={() => {
                    setTabIndex(1);
                  }}
                >
                  Curso
                </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            <Box
              role={"group"}
              w={"full"}
              bg={useColorModeValue("white", "gray.800")}
              boxShadow={"2xl"}
              rounded={"lg"}
              pos={"relative"}
              zIndex={1}
            >
              <Box
                rounded={"lg"}
                pos={"relative"}
                width={"full"}
                height={"300px"}
                _after={{
                  transition: "all .3s ease",
                  content: '""',
                  w: "full",
                  h: "full",
                  pos: "absolute",
                  top: 5,
                  left: 0,
                  backgroundImage: `url(${sobrancelhacurso})`,
                  filter: "blur(15px)",
                  zIndex: -1,
                }}
                _groupHover={{
                  _after: {
                    filter: "blur(20px)",
                  },
                }}
              >
                <Box
                  position={"relative"}
                  height={"300px"}
                  rounded={"2xl"}
                  boxShadow={"2xl"}
                  width={"full"}
                  overflow={"hidden"}
                >
                  <Image
                    fit={"cover"}
                    align={"center"}
                    w={"100%"}
                    h={"100%"}
                    src={`${sobrancelhacurso}`}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
        </Stack>

        <Tabs
          id={"section"}
          index={tabIndex}
          onChange={handleTabsChange}
          align="center"
          mt={8}
          mb={12}
          colorScheme="pink"
        >
          <TabList>
            <Tab value={0}>Consultoria</Tab>
            <Tab value={1}>Curso</Tab>
          </TabList>
        </Tabs>
        <Center>
          {tabIndex == 1 && (
            <Box>
              <ColunaSimples3 />
            </Box>
          )}
          {tabIndex == 0 && (
            <Box as={Container} maxW="6xl" mt={8} p={8}>
              <Grid gap={4}>
                <GridItem colSpan={1}>
                  <VStack alignItems="center" spacing="20px">
                    <chakra.h2 fontSize="3xl" fontWeight="700">
                      Consultoria Online
                    </chakra.h2>
                  </VStack>
                  <VStack alignItems="center" spacing="20px" mt={5}>
                    <chakra.h2 fontSize="1xl" fontWeight="700">
                      Estratégias para Captação de Clientes
                    </chakra.h2>
                  </VStack>
                  <Flex mt={4} maxW="5xl">
                    <Text align={"center"}>
                      Nossa Consultoria Online é uma oportunidade sob medida
                      para profissionais da área da beleza que desejam ampliar
                      sua clientela e aumentar sua presença no mercado.
                      Compreendemos que dominar as técnicas de design de
                      sobrancelhas é apenas um aspecto da jornada de sucesso. É
                      por isso que oferecemos orientação prática e estratégica
                      para ajudar você a atrair e manter clientes de forma
                      consistente.
                    </Text>
                  </Flex>
                </GridItem>
              </Grid>
              <Grid mt={12}>
                <VStack alignItems="center" spacing="20px">
                  <chakra.h2 fontSize="2xl" fontWeight="700">
                    O Que Oferecemos:
                  </chakra.h2>
                </VStack>
              </Grid>

              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                gap={{ base: "8", sm: "12", md: "16" }}
                mt={6}
              >
                <Feature
                  icon={<Icon as={FcApproval} w={10} h={10} />}
                  heading={"Análise Personalizada"}
                  text={
                    "Começamos com uma análise detalhada de sua situação atual. Avaliamos suas técnicas de design, presença online, estratégias de marketing existentes e identificamos áreas de melhoria."
                  }
                />
                <Feature
                  icon={<Icon as={FcBullish} w={10} h={10} />}
                  heading={"Estratégias de Marketing"}
                  text={
                    "Desenvolvemos estratégias de marketing adaptadas às suas necessidades. Isso inclui a definição de um público-alvo, identificação de canais de marketing eficazes e criação de conteúdo relevante para atrair a atenção de potenciais clientes."
                  }
                />
                <Feature
                  icon={<Icon as={FcTabletAndroid} w={10} h={10} />}
                  heading={"Presença Online"}
                  text={
                    "Uma presença online forte é fundamental hoje em dia. Ajudamos você a otimizar suas redes sociais, site e perfis profissionais, garantindo que eles transmitam sua expertise e estilo único."
                  }
                />
                <Feature
                  icon={<Icon as={FcManager} w={10} h={10} />}
                  heading={"Atendimento ao Cliente"}
                  text={
                    "O relacionamento com o cliente é essencial. Oferecemos orientação sobre como proporcionar uma experiência excepcional, desde o primeiro contato até o acompanhamento pós-serviço."
                  }
                />
                <Feature
                  icon={<Icon as={FcVip} w={10} h={10} />}
                  heading={"Acompanhamento e Feedback"}
                  text={
                    "Não deixamos você sozinho após a consultoria inicial. Fornecemos acompanhamento contínuo para avaliar o progresso, ajustar estratégias conforme necessário e fornecer feedback construtivo."
                  }
                />
              </Grid>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={4}
                mt={12}
              >
                <GridItem colSpan={1}>
                  <VStack alignItems="flex-start" spacing="20px">
                    <chakra.h2 fontSize="3xl" fontWeight="700">
                      Próximos Passos
                    </chakra.h2>
                    <a
                      target={"_blank"}
                      href={
                        "https://api.whatsapp.com/send/?phone=5561995591218&text=Ol%C3%A1%2C+Eu+gostaria+de+investir+na+minha+carreira+e+saber+mais+sobre+a+consultoria+online.&type=phone_number&app_absent=0"
                      }
                    >
                      <Button colorScheme="pink" size="md" bg={"#E5C0AC"} m={2}>
                        Quero Investir
                      </Button>
                    </a>
                  </VStack>
                </GridItem>
                <GridItem>
                  <chakra.h2 fontSize="2xl" fontWeight="700">
                    Invista na sua Carreira!
                  </chakra.h2>
                  <chakra.p>
                    Deseja impulsionar sua carreira na área da beleza e atrair
                    clientes de maneira consistente? Nossa Consultoria Online
                    oferece um caminho claro e orientado para o sucesso. Entre
                    em contato conosco para discutir suas necessidades,
                    objetivos e iniciar sua jornada rumo a uma clientela
                    próspera e satisfeita.
                  </chakra.p>
                </GridItem>
              </Grid>
            </Box>
          )}
        </Center>
      </Container>
      <Footer />
    </>
  );
}

const Feature = ({ icon, heading, text }) => {
  return (
    <Stack align={"center"} justify={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
      >
        {icon}
      </Flex>
      <Text align={"center"} justify={"center"} fontWeight={600}>
        {heading}
      </Text>
      <Text align={"center"} justify={"center"} color={"gray.300"}>
        {text}
      </Text>
    </Stack>
  );
};
