import { extendTheme } from "@chakra-ui/react";
 

// 2. Add your color mode config
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// 3. extend the theme
export const themeConfig = extendTheme({ config })

 
const colors = {
  primary: {
    100: "#fff",
    1100: "#ee605d",
    200: "#803b4e",
    300: "#47315b",
    400: "#47218c",
    500: "#b11513",
    600: "#D19C9C",
    900: "#111",
  },
};


const customTheme = extendTheme({ colors });

export default customTheme;
